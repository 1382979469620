<template>
  <div class="cart flex-y">
    <span class="tip f16">我的购物车</span>
    <div class="thead flex align-center f15">
      <div>
        <el-checkbox v-model="all" @change="checkAll">全选</el-checkbox>
      </div>
      <span>产品信息</span>
      <span>单价</span>
      <span>数量</span>
      <span>小计</span>
    </div>
    <div class="tbody">
      <div class="item flex-y" v-for="(item, index) in list" :key="index">
        <div class="store flex align-center">
          <el-checkbox
            v-model="item.checked"
            @change="checkStore($event, index)"
            :label="item.storeName"
          ></el-checkbox>
        </div>
        <div
          class="product flex align-center"
          v-for="(product, inx) in item.cartItemList"
          :key="inx"
        >
          <section class="flex align-center">
            <el-checkbox v-model="product.checked" @change="checkItem($event, index, inx)"></el-checkbox>
            <img :src="product.productPicUrl | imagePath">
          </section>
          <div class="flex-y flex-1">
            <span v-text="product.productName"></span>
            <span style="margin:5px 0;">产品货号:{{product.productSn}}</span>
            <div class="flex align-center flex-center">
              <span v-for="(item, index) in product.productAttr" :key="index">
                <span v-for="(value, inx) of item" :key="inx">{{value}}</span>
              </span>
            </div>
          </div>
          <div>￥{{product.productPrice | price}}</div>
          <div>
            <el-input-number v-model="product.productQuantity" :min="1"></el-input-number>
          </div>
          <div>￥:{{product.productPrice * product.productQuantity | price}}</div>
        </div>
      </div>
    </div>
    <div class="tfooter f14 flex align-center">
      <el-checkbox v-model="all" @change="checkAll">全选</el-checkbox>
      <span class="remove" @click="clearItem">删除</span>
      <div class="flex flex-1 align-center flex-end">
        <div>
          共
          <span v-text="totalNumber" style="margin:0;" class="price_color"></span>件产品
        </div>
        <div>
          <span style="margin-right:2px;">产品总价:</span>
          <span class="price_color">￥{{totalPrice | price}}</span>
        </div>
        <span style="margin-right:2px;">订单总金额(不含运费):</span>
        <span class="price_color">￥{{totalPrice | price}}</span>
        <button class="btn f16" :class="[totalPrice > 0 ? '' : 'disable']" @click="createOrder">结算</button>
      </div>
    </div>
    <div class="flex-y tips">
      <div>温馨提示:</div>
      <div class="flex align-center">
        <span class="express">运费</span>
        <span>运费问题请与商家协商沟通（运费差价可通过“补运费差价”产品支付）</span>
      </div>
      <div class="flex align-center">
        <span class="quan">赠</span>
        <span class="flex align-center">
          提交订单并确认收货成功后，将同时赠送您
          <b style="color:#ff0036">{{score}}积分</b>，可用于孔雀有礼
          <a style="color:#0db168;cursor:pointer;">积分商城</a> 礼品兑换~
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  data() {
    return {
      all: false,
      num: 1,
      list: [],
      totalPrice: 0,
      select: [],
      totalNumber: 0,
      score: 0
    }
  },
  mounted() {
    this.queryList()
  },
  computed: {
    ...mapGetters(['user', 'cartItems'])
  },
  methods: {
    ...mapMutations({ addOrder: 'CART_LIST', orderEntry: 'ORDER_ENTRY' }),
    ...mapActions(['queryCartList']),
    async queryList() {
      await this.queryCartList().then(res => {
        this.list = res.map(item => {
          item.cartItemList &&
            item.cartItemList.map(child => {
              child.checked = false
              return child
            })
          item.checked = false
          return item
        })
      })
    },
    /**
     * @description:计算价格
     * @param {type}
     * @return:
     */
    calcPrice() {
      let price = 0
      let total = 0
      this.list.forEach(item => {
        price += item.cartItemList.reduce((p, c) => {
          return p + (c.checked && c.productPrice * c.productQuantity)
        }, 0)
        total += item.cartItemList.reduce((p, c) => {
          return p + (c.checked ? 1 : 0)
        }, 0)
      })
      this.totalNumber = total
      this.totalPrice = price
      this.score = Math.floor(price)
    },
    /**
     * @description: 全选
     * @param {type}
     * @return:
     */
    checkAll(e) {
      this.list = this.list.map(item => {
        item.cartItemList.map(child => {
          child.checked = e
          return child
        })
        item.checked = e
        return item
      })
    },
    checkStore(e, index) {
      this.list = this.list.map((item, inx) => {
        if (index === inx) {
          item.cartItemList.map(child => {
            child.checked = e
            return child
          })
        }
        item.checked = e
        return item
      })
      if (e) {
        const size = this.list.filter(item => item.checked).length
        this.all = size > 0 ? true : false
      } else {
        this.all = false
      }
    },
    checkItem(e, index, childIndex) {
      this.list = this.list.map((item, inx) => {
        if (index === inx) {
          item.cartItemList = item.cartItemList.map((child, ix) => {
            if (childIndex === ix) child.checked = e
            return child
          })
        }
        const size = item.cartItemList.filter(element => element.checked).length
        item.checked = size === item.cartItemList.length ? true : false
        return item
      })
      if (e) {
        const size = this.list.filter(item => item.checked).length
        this.all = size === this.list.length ? true : false
      } else {
        this.all = false
      }
    },
    clearItem() {
      const ids = this.list
        .reduce((prev, cur) => {
          const idss = cur.cartItemList.filter(item => item.checked).map(c => c.cartItemId)
          prev.push(idss)
          return prev
        }, [])
        .flat()
      if (ids.length === 0) return
      this.$confirm('确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http.get('/cart/deleteByCartItemId', { params: { cartItemId: ids } }).then(res => {
            this.list.map(item => {
              item.cartItemList = item.cartItemList.filter(child => child.checked)
              return item
            })
          })
        })
        .catch(() => {})
    },
    createOrder() {
      if (this.totalPrice === 0) return
      const list = this.list.filter(item => item.checked || item.cartItemList.filter(child => child.checked).length > 0)
      this.addOrder(list)
      this.orderEntry(0)
      this.$router.push({ name: 'order' })
    }
  },
  watch: {
    list: {
      handler(value) {
        this.calcPrice()
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss" scoped>
.cart {
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  box-sizing: border-box;
  .tip {
    margin: 20px auto;
    width: 100%;
    border-left: 4px solid #0db168;
    padding-left: 10px;
  }
  .thead {
    height: 44px;
    background: #f5f5f5;
    width: 100%;
    margin-bottom: 20px;
    padding-left: 38px;
    box-sizing: border-box;
    div {
      width: 120px;
    }
    span {
      flex: 1;
      text-align: center;
      &:nth-of-type(1) {
        flex: 2;
      }
    }
  }
  .tbody {
    .item {
      margin-bottom: 20px;
      border: solid 1px #e5e5e5;
      .store {
        width: 100%;
        height: 44px;
        background: #f5f5f5;
        padding-left: 38px;
        box-sizing: border-box;
      }
      .product {
        padding: 20px 0 20px 38px;
        box-sizing: border-box;
        div {
          flex: 1;
          text-align: center;
          &:nth-of-type(1) {
            flex: 2;
          }
        }
        img {
          width: 80px;
          height: 80px;
        }
        &:not(:last-child) {
          border-bottom: 1px dashed #0db168;
        }
      }
    }
  }
  .tfooter {
    height: 44px;
    width: 100%;
    margin: 20px auto 30px;
    padding-left: 38px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    .remove {
      cursor: pointer;
      color: #ff0000;
    }
    div {
      span {
        margin-right: 20px;
      }
    }
    .btn {
      background: #0db168;
      outline: none;
      border: none;
      color: #fff;
      height: 44px;
      width: 100px;
      cursor: pointer;
      &.disable {
        background: #cccccc;
      }
    }
  }
  .tips {
    font-size: 14px;
    margin-bottom: 60px;
    padding-left: 38px;
    box-sizing: border-box;
    div {
      margin-bottom: 10px;
      .express {
        background: #ff9800;
        width: 40px;
        height: 18px;
        text-align: center;
        color: #fff;
        margin-right: 20px;
        font-size: 12px;
      }
      .quan {
        background: #0db168;
        color: #fff;
        width: 40px;
        text-align: center;
        height: 18px;
        margin-right: 20px;
        font-size: 12px;
      }
    }
  }
}
</style>
