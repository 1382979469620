<template>
    <div class="apply">
        <div class="graybg">
            <div class="title">
                <div class="center">商家入驻申请</div>
            </div>
            <div class="center">
                <div
                        class="ad"
                >
                    孔雀有礼，中国广告协会战略支持平台，中国全渠道广告推广制作平台，秉承“让天下4000万经商的人都用孔雀广告网，让天下6亿人都在孔雀广告网上赚到钱”的理念为广大用户提供优质专业的服务，孔雀广告网开发平台欢迎优质广告服务商入驻。
                </div>
                <div class="tips">商家入驻，请使用微信扫描下方二维码填写入驻信息，我们收到您的入驻申请，将会有专业的客户经理与您沟通联系，请保持电话畅通。</div>
                <div class="form">
                    <img src="../../assets/image/entering.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'mycenter',
        data() {
            return {

            }
        },
        methods: {}
    }
</script>

<style lang='scss' scoped>
    .apply {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        .graybg {
            // background: #f5f5f5;
            overflow: hidden;
            margin-top: 30px;
        }
        .center {
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;
            font-size: 14px;
        }
        .title {
            height: 46px;
            line-height: 46px;
            background-color: #d82229;
            color: #fff;
            font-weight: bold;
            padding-left: 20px;
            .center {
                font-size: 18px;
            }
        }
        .ad {
            background-color: #fff;
            padding: 10px 20px;
            margin: 30px 0;
        }
        .tips {
            background-color: #eaeaea;
            padding: 10px 20px;
            color: #666666;
        }

        .form {
            padding: 40px 20px;
            margin: 20px 0 60px;
            box-sizing: border-box;
            background-color: #fff;
            text-align: center;
            img{
                width: 300px;
            }
        }

    }
</style>
