<template>
  <div class="priceDetail">
    <div class="nav flex align-center">
      <el-breadcrumb separator-class="el-icon-productow-right">
        <el-breadcrumb-item>您的位置</el-breadcrumb-item>
        <el-breadcrumb-item>个人中心</el-breadcrumb-item>
        <el-breadcrumb-item>我的比价</el-breadcrumb-item>
        <el-breadcrumb-item>比价详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="bg1 flex align-center flex-center">{{product.state | compareStatus}}</div>
      <div class="div1">
        <h3 class="title1">比价信息</h3>
        <div class="div1-1">
          <div class="div1-1l">
            <img :src="product.pcUrl | imagePath" alt>
          </div>
          <div class="div1-1r">
            <div class="div1-1r-1">{{product.productName}}</div>
            <div class="div1-1r-2">比价单号：{{product.inviteId}}</div>
            <div class="div1-1r-4">
              <div class="div1-1r-4-1">比价联系信息</div>
              <div class="div1-1r-4-2 flex align-center">
                <span class="span1">联系人:</span>
                <span>{{product.linkMan}}</span>
              </div>
              <div class="div1-1r-4-3 flex align-center">
                <span class="span1">联系号码:</span>
                <span>{{product.phone}}</span>
              </div>
            </div>
            <div class="div1-1r-5" @click="closeHandler" v-show="product.state === 0">关闭此比价</div>
          </div>
        </div>
      </div>
      <div class="div2">
        <h3 class="title1">比价服务商</h3>
        <div class="div2-1">
          <span>当前共有</span>
          <span class="span2">{{list.length}}</span>
          <span>家服务商参加了报价</span>
        </div>
        <div class="div2-2">
          <ul class="ul1" v-for="(item,i) in list" :key="i">
            <li>
              <div class="li-1 flex align-center">
                <div class="li-1l">
                  <img :src="item.logoUrl | imagePath" alt>
                </div>
                <div class="li-1r">
                  <div class="li-1r-top">{{item.storeName}}</div>
                  <div class="li-1r-bottom">
                    <div class="li-1r-bottom-left">
                      <span class="span1">据您15.2公里</span>
                    </div>
                    <div class="li-1r-bottom-right">
                      <span class="span3">{{item.createDate}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="li-2">
                <span class="span1">￥{{item.price | price}}</span>
                <template v-if="item.state === 1">
                  <span class="span2" :class="{active : item.state === 1 }" @click="selectStore(item,product.inviteId)">{{item.state | storeStatus}}</span>
                </template>
                <template v-else>
                  <span class="span2" :class="{active : item.state === 1 }">{{item.state | storeStatus}}</span>
                </template>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api/api'
import Vue from 'vue'
import Header from '../../../components/common/header.vue'
import Footer from '@/components/common/footer.vue'
import Aside from '@/components/common/aside.vue'
import { mapMutations } from 'vuex'
export default {
  name: 'index',
  components: { Header, Footer, Aside }, //注册组件
  data() {
    return {
      product: [],
      list: []
    }
  },
  methods: {
    ...mapMutations({ orderEntry: 'ORDER_ENTRY', saveProduct: 'ORDER_PRODUCT', saveStore: 'STORE_INFO' }),
    priceDetail() {
      let id = this.$route.params.id
      api.priceDetail({ id: id }).then(res => {
        this.product = res.data.data
        this.list = res.data.data.storeList
      })
    },
    closeHandler() {
      this.$confirm('确认关闭此报价?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http.get('inviteInfo/deleteById', { params: { id: this.$route.params.id } }).then(res => {
            const { code, msg } = res.data
            if (code * 1 === 0) {
              this.priceDetail()
              this.$message({
                type: 'success',
                message: '关闭报价成功!',
                duration: 1000,
                onClose: () => {}
              })
            } else {
              this.$message({
                type: 'error',
                message: msg
              })
            }
          })
        })
        .catch(() => {})
    },
    selectStore(item,inviteSn) {
        this.$http.get ("inviteInfo/updateState?id="+inviteSn+"&state=1&storeId="+item.storeId).then(res=>{
            if (res.data.code === 0 || res.data.code === 200) {
                this.$router.push({ name: 'orderPrice' ,params:{item: item,inviteSn:inviteSn}})
            }
        });
    }
  },
  mounted() {
    this.priceDetail()
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/common';
h2,
ul,
li {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
  border-bottom: 1px solid #ccc;
}
.nav {
  height: 44px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  color: #666;
  line-height: 44px;
  font-size: 14px;
}
.priceDetail {
  width: 100%;
  background: #f5f5f5;
  font-size: 15px;
  .content {
    width: 100%;
    max-width: 1200px;
    height: 1240px;
    background: #fff;
    margin: 0 auto;
    overflow: hidden;
    .bg1 {
      width: 140px;
      height: 50px;
      color: #fff;
      background: #22be78;
      font-size: 20px;
      text-align: center;
      border-radius: 30px;
      margin: 20px 0 40px -25px;
      padding-left: 15px;
    }
    .div1 {
      width: 1142px;
      height: 478px;

      margin: 0 auto;
      .title1 {
        height: 20px;
        line-height: 22px;
        font-size: 18px;
        font-weight: 100;
        padding-left: 12px;
        background: url('../../../assets/image/fenleitiao.png') no-repeat 0 2px;
        background-size: 4px 20px;
      }
      .div1-1 {
        margin-top: 30px;
        width: 100%;
        height: 431px;
        .div1-1l {
          width: 400px;
          height: 400px;
          float: left;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        .div1-1r {
          width: 700px;
          height: 400px;

          float: right;
          .div1-1r-1 {
            height: 64px;
            font-size: 24px;
            line-height: 64px;
            color: #666666;
            margin-top: 13px;
          }
          .div1-1r-2,
          .div1-1r-3 {
            font-size: 14px;
            color: #999999;
            height: 37px;
            font-size: 14px;
            line-height: 37px;
            color: #666666;
          }
          .div1-1r-4 {
            width: 430px;
            height: 140px;
            margin-top: 20px;
            margin-bottom: 20px;
            background: #f5f5f5;
            padding-left: 20px;
            .div1-1r-4-1 {
              height: 50px;
              line-height: 50px;
              font-size: 16px;
            }
            .div1-1r-4-2,
            .div1-1r-4-3 {
              height: 40px;
              line-height: 40px;
              font-size: 16px;
              .span1 {
                color: #666;
              }
            }
          }
          .div1-1r-5 {
            width: 90px;
            height: 30px;
            border: 1px solid #e5e5e5;
            background-color: #0db168;
            margin-top: 7px;
            text-align: center;
            line-height: 30px;
            color: #FFF;
            cursor: pointer;
          }
        }
      }
    }
    .div2 {
      width: 1142px;
      height: 580px;
      margin: 0 auto;
      .title1 {
        height: 20px;
        line-height: 22px;
        font-size: 18px;
        padding-left: 12px;
        font-weight: 100;
        background: url('../../../assets/image/fenleitiao.png') no-repeat 0 2px;
        background-size: 4px 20px;
      }
      .div2-1 {
        height: 29px;
        line-height: 29px;
        margin-top: 20px;
        .span2 {
          color: #0db168;
        }
      }
      .div2-2 {
        width: 1140px;
        height: 509px;

        margin-top: 5px;
        li {
          border: 0;
          height: 99px;
        }
        .li-1 {
          width: 440px;
          height: 99px;
          line-height: 99px;
          float: left;

          > div {
            float: left;
          }
          .li-1l {
            width: 84px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            img {
              width: 64px;
              height: 64px;
            }
          }
          .li-1r {
            width: 355px;
            .li-1r-top {
              height: 32px;
              line-height: 32px;
              margin-top: 16px;
            }
            .li-1r-bottom {
              height: 45px;
              line-height: 45px;
              font-size: 15px;
              color: #666;
              background: url('../../../assets/image/shufenge.png') no-repeat 143px 16px;
              background-size: 1px 13px;
              > div {
                float: left;
              }
              .li-1r-bottom-left {
                padding-left: 26px;
                background: url('../../../assets/image/dingwei.png') no-repeat 0 13px;
              }
              .li-1r-bottom-right {
                padding-left: 83px;
                background: url('../../../assets/image/jiezhishijian.png') no-repeat 58px 16px;
              }
              .span2 {
                margin-left: 25px;
                margin-right: 30px;
              }
            }
          }
        }
        .li-2 {
          width: 425px;
          height: 99px;
          float: left;
          line-height: 99px;
          font-size: 16px;
          .span1 {
            display: block;
            float: left;
            width: 250px;
            color: #ff0036;
            text-align: center;
          }
          .span2 {
            display: block;
            float: left;
            width: 110px;
            height: 40px;
            line-height: 40px;
            margin-top: 30px;
            text-align: center;
            border-radius: 3px;
            background: #fff;
              color: #ccc;
            cursor: pointer;
            &.active {
              background: #0db168;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>
